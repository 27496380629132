
/**
 * Object with the labels
 */

export const fieldDescription: { [key: string]: string; } = {
    "A1": "Customer's name",
    "A2": "Customer's identity card number",
    "A3": "Customer's address",
    "A4": "Postal code",
    "A5": "Customer's city",
    "A6": "Customer's province",
    "B1": "Customer's name",
    "B2": "Customer's identity card number",
    "B3": "Customer's address",
    "B4": "Postal code",
    "B5": "Customer's city",
    "B6": "Customer's province",
    "C1": "Marketer name",
    "C2": "Marketer tax identification code",
    "C3": "Marketer address",
    "C4": "Marketer postal code",
    "C5": "Marketer city",
    "C6": "Marketer province",
    "C7": "Marketer register information",
    "C8": "Marketer administration address",
    "C9": "Marketer website",
    "CA": "Marketer email",
    "CB": "Marketer short name",
    "CC": "Marketer contact telephone",
    "CD": "Customer support telephone",
    "CE": "Marketer telephone for claims",
    "D1": "Distributor name",
    "D2": "Distributor tax identification code",
    "D9": "Distributor website",
    "DC": "Distributor public service phone",
    "DD": "Distributor telephone for breakdowns",
    "E1": "Universal Supply Point Code (Código Universal del Punto de Suministro - CUPS)",
    "E2": "Contract type",
    "E3": "Contracted electric power",
    "E4": "Contract number",
    "E5": "Electricity meter number",
    "E6": "Access toll",
    "E7": "Contract end date",
    "E8": "Code for the National Classification of Economic Activities (Clasificación Nacional de Actividades Económicas - CNAE)",
    "E9": "Reference supply contract",
    "F1": "Invoice number",
    "F2": "Invoice reference",
    "F3": "Invoice release date",
    "F4": "Start billing date",
    "F5": "End billing date",
    "F6": "Total number of billing days",
    "F7": "Days per year",
    "F8": "Number of months",
    "G1": "Payment method",
    "G2": "IBAN (International Bank Account Number)",
    "G3": "Payment date",
    "G4": "Payment operation code",
    "G5": "Payment operation code 2",
    "G6": "Bank code",
    "G7": "Bank office code",
    "G8": "Bank control digits",
    "G9": "Bank account number",
    "GA": "Bank name",
    "I1": "Previous energy consumption",
    "I2": "Current energy consumption",
    "I3": "Energy comsumption",
    "J1": "Electric power price",
    "J2": "Energy price",
    "J3": "Subtotal 1",
    "J4": "Subtotal 2", 
    "J5": "Total price",
    "K2": "Access toll rate", 
    "K3": "Access toll price",
    "K4": "Marketer cost rate",
    "K5": "Marketer cost price",
    "K6": "Power rate",
    "K9": "Access toll energy rate",
    "KA": "Access toll energy price",
    "KB": "Energy cost rate",
    "KC": "Energy cost price",
    "KD": "Energy rate",
    "M3": "Daily equipment rental price",
    "M4": "Equipment rental price",
    "N1": "Electricity tax rate",
    "N2": "Electricity tax price",
    "N3": "Electricity tax and equipment rental price", 
    "N4": "Normal tax rate",
    "N5": "Reduced tax price",
    "N6": "Reduced tax rate",
    "N7": "Energy price",
    "N8": "Tax price"
}